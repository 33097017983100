@import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700&subset=cyrillic,cyrillic-ext,latin-ext');
$colorTurquoise: rgba(26, 188, 156, 1);
$colorGreenSea: rgba(22, 160, 133, 1);
$colorEmerald: rgba(46, 204, 113, 1);
$colorNephritis: rgba(39, 174, 96, 1);
$colorPeterRiver: rgba(52, 152, 219, 1);
$colorBelizeHole: rgba(41, 128, 185, 1);
$colorAmethyst: rgba(155, 89, 182, 1);
$colorWisteria: rgba(142, 68, 173, 1);
$colorWetAsphalt: rgba(52, 73, 94, 1);
$colorMidnightBlue: rgba(44, 62, 80, 1);
$colorSunFlower: rgba(241, 196, 15, 1);
$colorOrange: rgba(243, 156, 18, 1);
$colorCarrot: rgba(230, 126, 34, 1);
$colorPumpkin: rgba(211, 84, 0, 1);
$colorAlizarin: rgba(231, 76, 60, 1);
$colorPomegranate: rgba(192, 57, 43, 1);
$colorClouds: rgba(236, 240, 241, 1);
$colorSilver: rgba(189, 195, 199, 1);
$colorConcrete: rgba(149, 165, 166, 1);
$colorAsbestos: rgba(127, 140, 141, 1);
$colorWhite: rgba(255, 255, 255, 1);
$colorBlack: rgba(0, 0, 0, 1);
$colorLightGreen: rgba(245, 255, 67, 1);
$colorMiddleGreen: rgba(166, 210, 67, 1);
$colorDarkGreen: rgba(85, 107, 39, 1);
$colorLightBlue: rgba(126, 231, 255, 1);
$gradient_color: rgba(241, 196, 15, 1.0);
$empty_color: rgba(241, 196, 15, 0.3);

$media: screen;
$min: max-width;
$max: max-width;
$small: 320px;
$middle: 468px;
$normal: 768px;
$big: 1024px;
$large: 1280px;
$hd: 1980px;
$thin: 300;
$bold: 500;

$little: 14px;
$norm: 18px;
$medium: 21px;
$high: 36px;
*{
  margin: 0;
  padding: 0;
}
section{
  position: relative;
  z-index: 2;
}
.row{
  margin: 0 !important;
}
body {
  width: 100%;
  min-width:  320px !important;
  padding:0;
  background: none;
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  font: {
    family: 'Roboto', sans-serif;
    weight: 400;
    size: 14px;
  }
}
h2 {
  font:{
    size: 36px;
    weight: 300;
  };
  text-align: center;
  line-height: 50px;
  color: $colorBelizeHole;
  margin: 0 0 30px;
}
.loading{
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.header {
  text-align: center;
  background: $colorBelizeHole;
  color: $colorWhite;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media #{$media} and (orientation:landscape) and ($max: $normal) {
    height: 500px;
  }
  &__image {
    width: 300px;
    height: 300px;
    border-radius: 75px;
    display: inline-block;
  }
  &__text {
    font: {
      size: 56px;
      weight: 400;
    }
  }
  &__name {
    line-height: 100px;
    height: 100px;
    display: block;
    border-bottom: 1px solid $colorWhite;
    box-sizing: border-box;
    font: {
      size: 34px;
      weight: 300;
    }
    @media #{$media} and ($max: $normal) {
      line-height: 40px;
      height: 40px;
    }
  }
  &__icons {
    margin: 0 0 15px;
  }
  &__skills {
    height: 100px;
    max-width: 830px;
    line-height: 50px;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    @media #{$media} and ($max: $normal) {
      //height: 200px;
      height: 80px;
      line-height: 40px;
    }
    &--item{
      display: block;
      line-height: 50px;
      white-space: nowrap;
      font: {
        size: 32px;
        weight: 100;
      };
      @media #{$media} and ($max: $normal) {
        line-height: 40px;
        font: {
          size: 21px;
          weight: 300;
        };
       }
    }

  }
}
  .skills {
  width: 100%;
  box-sizing: border-box;
  padding: 50px 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media #{$media} and ($max: $normal) {
    padding: 50px 25px 70px;
  }
  &:after {
    content: '';
    clear: both;
    display: block;
  }
  &__logos {
    display: block;
    &:after {
      content: '';
      display: block;
      float: none;
      clear: both;
    }
    &--item {
      list-style: none;
      float: left;
      text-align: center;
      margin: 0 0 35px;
      width: 20%;
      @media #{$media} and ($max: $big) {
        width: 50%;
      }
      @media #{$media} and ($max: $middle) {
        width: 100%;
      }
      &__image {
        border: none;
        width: 150px;
        height: 212px;
        margin: 0 auto;
        display: block;
      }
    }
  }
}
  .tools {
  display: block;
  width: 100%;
  margin: 0 auto;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
  &__litter {
  }
  &__items {
    margin: 0 auto 50px;
    padding: 0 0 0 18px;
    width: 85%;
    max-width: 800px;
    line-height: 30px;
    &--item {
      &__title {
        display: inline;
        font: {
          size: 20px;
          weight: 500;
        }
        @media #{$media} and ($max: $normal) {
          display: block;
          line-height: 20px;
        }
      }
      &__info {
        display: inline;
        margin: 0 0 0 5px;
        font: {
          size: 20px;
          weight: 300;
        }
        @media #{$media} and ($max: $normal) {
          margin: 0;
          line-height: 20px;
        }
      }
    }
  }
}
  .footer {
  background: $colorAlizarin;
  padding-top: 20px;
  padding-bottom: 30px;
  &:after {
    content: '';
    clear: both;
    display: block;
  }
    @media #{$media} and (orientation:landscape) and ($max: $normal) {
      height: 500px !important;
    }
    @media #{$media} and ($max: $normal) {
     height: 100vh;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }


  &__litter {
    font:{
      size: 34px;
      weight: 300;
    }
  }
  &__link {
    color: $colorWhite;
    text-decoration: none;
    border-bottom: 1px solid $colorWhite;
    font: {
      size: 16px;
      weight: 300;
    }
  }
  &__address {
    margin: 0 0 10px;
    font: {
      weight: 300;
      size: 21px
    };
    line-height: 24px;
    display: block;
    text-align: center;
  }
  &__social {
    list-style: none;
    &--item {
      width: 56px;
      height: 56px;
      display: inline-block;
      &__link {
        border-bottom: none !important;
        & img {
          transform: translate(0, 0);
          width: 42px !important;
          height: 42px !important;
        }
        & svg{
          width: 42px !important;
          height: 42px !important;
          fill: $colorWhite!important;
        }
      }
    }
  }
  &--item {
    width: 33%;
    float: left;
    text-align: center;
    color: $colorWhite;
    @media #{$media} and ($max: $normal) {
      width: 100%;
      float: none;
      margin: 0 0 50px;
    }
    & p {
      &.coming-out {
        font: {
          size: 14px
        }
      ;
      }
    }
  }
  &__moto {
    text-align: center;
    color: $colorWhite;
    padding: 0 0 10px;
  }
}
  .site-error {
  & h1 {
    text-align: center;
    font: {
      size: 150px;
      weight: 400;
    }
  ;
  }
  & h2 {
    text-align: center;
    font: {
      size: 75px;
      weight: 400;
    }
  ;
  }
  & p {
    text-align: center;
    a {

    }
  }
}
